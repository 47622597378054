<template>
    <v-data-table
        class="elevation-0"
        item-key="id"
        :headers="headers"
        :items="movements"
        :loading="isLoading"
        no-data-text="No hay movimientos para mostrar"
        must-sort
    >
        <template slot="item" slot-scope="props">
            <tr>
                <td>{{ props.item.createdAt | moment('D/M/Y') }}</td>
                <td>{{ props.item.quantity }}</td>
                <td>{{ props.item.balance }}</td>
                <td>{{ props.item.comment }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        components: { },

        props: {
            product: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                amount: 0,
                comment: '',

                isLoading: false,
                dialog: false,

                headers: [
                    { text: 'Fecha', value: 'created_at', sortable: false },
                    { text: 'Movimiento', value: 'quantity', sortable: false },
                    { text: 'Balance', value: 'balance', sortable: false },
                    { text: 'Comentario', value: 'comment', sortable: false }
                ]
            };
        },

        computed: {
            stock() {
                if(!this.product) {
                    return null;
                }

                return this.$store.getters['stock/getByProductId'](this.product.id);
            },

            movements() {
                if(this.stock && this.stock.movements) {
                    return this.stock.movements;
                }

                return [];
            }
        },

        watch: {
            product: {
                handler(v) {
                    if(!v) {
                        return;
                    }

                    this.isLoading = true;

                    this.$store.dispatch('stock/fetchMovements', v.id).finally(() => {
                        this.isLoading = false;
                    });
                },
                immediate: true
            }
        },

        methods: {

        }
    };
</script>
