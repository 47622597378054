<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="product">
            <v-row align="center">
                <v-col>
                    <v-row>
                        <v-col class="py-0" >
                            <h4 class="headline blue-grey--text text--darken-3">{{ product.name }}
                                <span class="text--lighten-1 grey--text">#{{ product.id }}</span></h4>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-form>
                        <v-switch
                            v-model="fields.publicSale"
                            label="Publicado"
                        ></v-switch>

                        <v-text-field
                            v-model="fields.name"
                            label="Nombre"
                            required
                        ></v-text-field>

                        <product-category-select
                            v-model="fields.category"
                        />

                        <v-text-field
                            v-model="fields.presentation"
                            label="Presentación"
                            counter
                        ></v-text-field>

                        <v-textarea
                            v-model="fields.description"
                            label="Descripción"
                            counter
                        ></v-textarea>

                        <v-file-input
                            class="mb-4"
                            v-model="fields.thumbnail"
                            label="Vista previa"
                            prepend-icon=""
                            append-icon="mdi-camera-outline"
                            hint="Recomendado: 200 x 150 px"
                            persistent-hint
                            show-size
                        />

                        <v-row align="center">
                            <v-col class="flex-grow-0">
                                <v-icon>mdi-currency-usd</v-icon>
                            </v-col>
                            <v-col class="px-0">
                                <span class="title">Precio</span>
                            </v-col>
                        </v-row>

                        <v-text-field
                            v-model="fields.price"
                            label="Precio"
                            prefix="$"
                        ></v-text-field>

                        <v-select
                            label="Impuesto"
                            v-model="fields.tax"
                            :items="taxOptions"
                            prefix="%"
                        />

                        <v-text-field
                            v-model="basePrice"
                            label="Precio Base"
                            prefix="$"
                            readonly
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>

            <template v-if="stockFields">
                <v-row align="center" class="section-title">
                    <v-col class="flex-grow-0">
                        <v-icon>mdi-package-variant-closed</v-icon>
                    </v-col>
                    <v-col class="px-0">
                        <span class="title">Stock</span>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-form>
                            <v-text-field
                                label="Corte de Venta"
                                v-model="stockFields.stopSellingStockLevel"
                                suffix="Unidades"
                            ></v-text-field>

                            <v-text-field
                                label="Stock Mínimo"
                                v-model="stockFields.minimumStockLevel"
                            ></v-text-field>

                            <v-switch v-model="stockFields.perishable" label="Perecedero"></v-switch>
                        </v-form>
                    </v-col>
                </v-row>
            </template>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="onCancelSelected">Cancelar</v-btn>
                <v-btn color="primary" @click="onSaveSelected">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import ProductCategorySelect from '@/components/Product/ProductCategorySelect';

    export default {
        components: {
            ProductCategorySelect
        },

        data() {
            return {
                active: false,

                categories: [],

                fields: {
                    name: '',
                    category: null,
                    price: '',
                    tax: '',
                    presentation: '',
                    description: ''
                },

                stockFields: {
                    minimumStockLevel: '',
                    stopSellingLevel: '',
                    perishable: false
                },

                taxOptions: [
                    { text: '0', value: 0.0 },
                    { text: '21', value: 21.0 },
                    { text: '10.5', value: 10.5 }
                ]
            };
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            product: {
                type: Object
            }
        },

        computed: {
            stock() {
                return this.$store.getters['stock/getByProductId'](this.product.id);
            },

            basePrice() {
                return (this.fields.price / (1 + this.fields.tax / 100)).toFixed(2);
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            product: {
                handler(val) {
                    if(!val) {
                        return;
                    }

                    this.fields = {
                        name: val.name,
                        category: val.category,
                        price: val.price,
                        tax: val.tax,
                        presentation: val.presentation,
                        description: val.description,
                        publicSale: val.publicSale
                    };

                    if(this.stock) {
                        this.stockFields = {
                            minimumStockLevel: this.stock.minimumStockLevel,
                            stopSellingStockLevel: this.stock.stopSellingStockLevel,
                            perishable: this.stock.perishable
                        };
                    }
                    else {
                        this.stockFields = null;
                    }
                },
                immediate: true
            }
        },

        methods: {
            async onSaveSelected() {
                let payload = { ...this.fields };
                payload.category = this.fields.category.id;

                try {
                    await this.$store.dispatch('products/patch', { product: this.product, data: payload });

                    if(this.stockFields != null) {
                        await this.$store.dispatch('stock/patch', { stock: this.stock, data: this.stockFields });
                    }

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header {
        h4.headline {
            font-size: 20px !important;
        }

        .main-image {
            background-color: white;
            margin-right: 20px;

            img {
                width: 200px;
            }
        }

        .v-btn {
            margin: 0;
        }
    }
</style>
