<template>
    <v-data-table
        hide-default-footer
        hide-default-header
        item-key="id"
        :headers="headers"
        :items="products"
        :loading="isLoading"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.itemsPerPage"
        no-data-text="No hay productos para mostrar"
        must-sort
        :disable-sort="true"
        @click:row="onRowSelected"
        :mobile-breakpoint="0"
    >
        <template v-slot:item.thumbnail="{item}">
            <v-img
                :height="imageSize"
                :width="imageSize"
                :src="item.thumbnail | toCDNPath"
                contain
            ></v-img>
        </template>

        <template v-slot:item.name="{item}">
            {{ item.name | capitalize }}
        </template>

        <template v-slot:item.price="{item}">
            {{ item.price | toCurrency }}
        </template>

        <template v-slot:item.stock="{item}">
            <template v-if="getStockForProduct(item)">
                <v-icon :color="getStockForProduct(item).currentStock < getStockForProduct(item).minimumStockLevel ? 'red' : ''">mdi-package-variant-closed</v-icon>

                <span :class="getStockForProduct(item).currentStock < getStockForProduct(item).minimumStockLevel ? 'red--text font-weight-medium' : ''">
                    {{ getStockForProduct(item).currentStock }}
                </span>
            </template>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        components: {
        },

        data() {
            return {
                isLoading: false,

                pagination: {
                    page: 1,
                    itemsPerPage: -1
                },

                sorting: {
                    sortBy: 'name',
                    sortDescending: false
                },

                headers: [
                    { text: '', value: 'thumbnail', class: 'thumbnail' },
                    { text: 'Producto', value: 'name' },
                    { text: 'Precio', value: 'price' },
                    { text: 'Stock', value: 'stock' }
                ]
            };
        },

        props: {
            products: Array,
            search: String,
            filtered: Boolean
        },

        watch: {
            pagination: {
                handler: function(val) {
                    this.$emit('update:pagination', val);
                },
                deep: true
            }
        },

        computed: {
            imageSize() {
                switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 48;
                case 'sm': return 96;
                case 'md': return 96;
                case 'lg': return 96;
                case 'xl': return 96;
                }

                return 0;
            }
        },

        mounted() {
            let sortBy = this.$route.query.sortBy ? this.$route.query.sortBy : 'name';
            let sortDir = this.$route.query.sortDir === 'desc';

            this.pagination.sortBy = sortBy;
            this.pagination.descending = sortDir;
        },

        methods: {
            getStockForProduct(product) {
                return this.$store.getters['stock/getByProductId'](product.id);
            },

            onRowSelected(product) {
                this.$emit('input', product);
            }
        }
    };
</script>

<style lang="scss">
    .thumbnail {
        width: 96px;
    }

    .filters {
        height: 48px;
        font-size: 0.9em;
    }
</style>
