<template>
    <v-select
        v-model="category"
        :label="label"
        :error-messages="errorMessages"
        :items="categories"
        item-value="id"
        item-text="name"
        :loading="isLoading"
        return-object
    ></v-select>
</template>

<script>
    export default {
        components: {},

        props: {
            value: Object,
            label: String,
            errorMessages: Array
        },

        data() {
            return {
                isLoading: false,
                categories: [],
                category: null
            };
        },

        mounted() {
            this.isLoading = true;

            this.$api.getCategories().then((categories) => {
                this.categories = categories;
            }).finally(() => {
                this.isLoading = false;
            });
        },

        watch: {
            value: {
                handler(v) {
                    this.category = v;
                },
                immediate: true
            },

            category(v) {
                this.$emit('input', v);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
