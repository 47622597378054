var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","item-key":"id","headers":_vm.headers,"items":_vm.products,"loading":_vm.isLoading,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"no-data-text":"No hay productos para mostrar","must-sort":"","disable-sort":true,"mobile-breakpoint":0},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"click:row":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"height":_vm.imageSize,"width":_vm.imageSize,"src":_vm._f("toCDNPath")(item.thumbnail),"contain":""}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.name))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.price))+" ")]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [(_vm.getStockForProduct(item))?[_c('v-icon',{attrs:{"color":_vm.getStockForProduct(item).currentStock < _vm.getStockForProduct(item).minimumStockLevel ? 'red' : ''}},[_vm._v("mdi-package-variant-closed")]),_c('span',{class:_vm.getStockForProduct(item).currentStock < _vm.getStockForProduct(item).minimumStockLevel ? 'red--text font-weight-medium' : ''},[_vm._v(" "+_vm._s(_vm.getStockForProduct(item).currentStock)+" ")])]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }