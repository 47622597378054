<template>
    <template-layout>
        <template slot="drawers">
            <v-navigation-drawer
                v-model="showDetailsDrawer"
                right
                fixed
                temporary
                width="600"
            >
                <product-view
                    v-if="showDetailsDrawer"
                    :product="selectedProduct"
                    @edit="showEditDrawer = true"
                ></product-view>
            </v-navigation-drawer>

            <product-create-drawer
                v-model="showCreateDrawer"
            ></product-create-drawer>

            <product-edit-drawer
                v-model="showEditDrawer"
                :product="selectedProduct"
                :active.sync="showEditDrawer"
            ></product-edit-drawer>

            <stock-movement-drawer
                v-model="showStockMovementsDrawer"
                v-if="showStockMovementsDrawer"
                :active.sync="showStockMovementsDrawer"
            />
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Productos</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-text-field
                            class="mr-12"
                            label="Buscar..."
                            color="primary" dense hide-details flat
                            prepend-icon="mdi-magnify"
                            v-model="search"
                            clearable
                            style="max-width: 180px;"
                        />
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-btn icon @click="showFilters = !showFilters"><v-icon>mdi-tune</v-icon></v-btn>
                        <v-btn icon @click="showStockMovementsDrawer = true"><v-icon>mdi-swap-vertical</v-icon></v-btn>
                        <v-btn fab x-small color="primary" class="elevation-0 mx-4" @click="showCreateDrawer = true;" :disabled="!canCreate"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>Productos</v-card-title>

                            <v-card-text>
                                <v-row v-if="showFilters || filtered" align="center" class="filters-row px-4 py-4">
                                    <v-col cols="12" sm="3" lg="2">
                                        <v-select
                                            label="Estado"
                                            :items="optionsState"
                                            item-text="name"
                                            item-value="value"
                                            v-model="filters.state"
                                            hide-details dense
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="3" lg="2">
                                        <v-select
                                            label="Tipo de Cliente"
                                            :items="optionsCustomerType"
                                            item-text="name"
                                            item-value="value"
                                            v-model="filters.customerType"
                                            hide-details dense
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="3" lg="2">
                                        <v-select
                                            label="Categoria"
                                            :items="optionsCategories"
                                            item-text="name"
                                            v-model="filters.category"
                                            hide-details dense
                                        />
                                    </v-col>

                                    <v-spacer></v-spacer>

                                    <v-col cols="12" sm="3" lg="2" class="py-0">
                                        <span class="grey--text">Mostrando {{ filteredProducts.length }} resultados.</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text>
                                <product-list-table
                                    class="elevation-0"
                                    v-model="selectedProduct"
                                    :search="search"
                                    :products="filteredProducts"
                                    :filtered="filtered"
                                    :filters.sync="filters"
                                    :pagination.sync="pagination"
                                ></product-list-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import ProductListTable from './ProductListTable';
    import ProductView from './ProductView';
    import ProductEditDrawer from './ProductEditDrawer';
    import ProductCreateDrawer from './ProductCreateDrawer';
    import StockMovementDrawer from './StockMovementDrawer';

    export default {
        components: {
            ProductView,
            ProductListTable,
            StockMovementDrawer,
            TemplateLayout,
            ProductEditDrawer,
            ProductCreateDrawer
        },

        data() {
            return {
                showDetailsDrawer: false,
                showCreateDrawer: false,
                showEditDrawer: false,
                showStockMovementsDrawer: false,

                showFilters: false,

                optionsState: [
                    { name: 'Todos', value: undefined },
                    { name: 'Publicado', value: true },
                    { name: 'No Publicado', value: false }
                ],

                optionsCustomerType: [
                    { name: 'Todos', value: undefined },
                    { name: 'Mayorista', value: true },
                    { name: 'Minorista', value: false }
                ],

                selectedProduct: null,
                search: '',
                pagination: {},

                defaultFilters: {
                    state: true,
                    category: 'Todas',
                    customerType: 'Todos'
                },

                filters: {}
            };
        },

        computed: {
            canCreate() {
                return true;
            },

            products() {
                return this.$store.getters['products/getAll']();
            },

            optionsCategories() {
                let r = [ 'Todas' ];
                r = r.concat(this.products.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index));
                return r;
            },

            filtered() {
                return this.filters.state !== this.defaultFilters.state ||
                    this.filters.category !== this.defaultFilters.category ||
                    this.filters.customerType !== this.defaultFilters.customerType ||
                    (this.search && this.search !== '');
            },

            filteredProducts() {
                let search = this.search ? this.search.toLowerCase() : null;

                return this.products.filter(product => {
                    if(typeof this.filters.state === 'boolean' && product.publicSale !== this.filters.state) {
                        return false;
                    }

                    if(typeof this.filters.category === 'string' && (product.category.name !== this.filters.category && this.filters.category !== 'Todas')) {
                        return false;
                    }

                    if(typeof this.filters.customerType === 'boolean' && product.isWholesaler !== this.filters.customerType) {
                        return false;
                    }

                    let searchProductName = product.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    if(search && !searchProductName.includes(search)) {
                        return false;
                    }

                    return true;
                });
            }
        },

        watch: {
            showStockMovementsDrawer(val) {
                if(val) {
                    this.showDetailsDrawer = false;
                    this.showEditDrawer = false;
                }
            },

            showEditDrawer(val) {
                if(val) {
                    this.showDetailsDrawer = false;
                    this.showStockMovementsDrawer = false;
                }
            },

            showCreateDrawer(val) {
                if(val) {
                    this.showDetailsDrawer = false;
                    this.showStockMovementsDrawer = false;
                    this.showEditDrawer = false;
                    this.showCreateDrawer = true;
                }
            },

            showDetailsDrawer(val) {
                if(val) {
                    this.showEditDrawer = false;
                    this.showStockMovementsDrawer = false;
                }
            },

            search(val) {
                this.updateRouteQuery();
            },

            pagination(val) {
                this.updateRouteQuery();
            },

            filters: {
                handler: function(val) {
                    this.updateRouteQuery();
                },
                deep: true
            },

            selectedProduct(product) {
                if(!product) {
                    return;
                }

                this.showDetailsDrawer = true;
            }
        },

        mounted() {
            // start with our default filters
            this.filters = { ...this.defaultFilters };

            // show filters if we start with a filtered list
            if(this.filtered) {
                this.showFilters = true;
            }

            let search = this.$route.query.search ? this.$route.query.search : null;
            this.search = search;

            if(this.$route.query.state) {
                this.filters.state = this.$route.query.state;
            }
            if(this.$route.query.category) {
                this.filters.category = this.$route.query.category;
            }
            if(this.$route.query.state) {
                this.filters.customerType = this.$route.query.customerType;
            }

            this.fetchProducts();
        },

        methods: {
            updateRouteQuery() {
                let q = {};

                if(this.search) {
                    q.search = this.search;
                }

                if(this.pagination.sortBy !== 'name' || this.pagination.descending !== false) {
                    q.sortBy = this.pagination.sortBy;
                    q.sortDir = this.pagination.descending ? 'desc' : 'asc';
                }

                if(this.filters) {
                    let keys = Object.keys(this.filters);
                    for(const k of keys) {
                        if(this.filters[k] !== this.defaultFilters[k]) {
                            q[k] = this.filters[k];
                        }
                    }
                }

                this.$router.replace({ name: 'productList', query: q }).catch(() => {});
            },

            fetchProducts() {
                this.isLoading = true;

                return this.$store.dispatch('products/fetchAll').then((result) => {
                    return this.$store.dispatch('stock/fetchAll');
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
