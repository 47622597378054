<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row class="fill-height" align="center">
                <v-col>
                    <v-row>
                        <v-col class="py-0" >
                            <h4 class="headline blue-grey--text text--darken-3">Crear Producto</h4>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <validation-observer ref="form">
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12">
                            <v-form>
                                <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                    <v-text-field
                                        v-model="fields.name"
                                        label="Nombre"
                                        :error-messages="errors"
                                    />
                                </validation-provider>

                                <validation-provider name="category" v-slot="{ errors }" rules="required">
                                    <product-category-select
                                        v-model="fields.category"
                                        label="Categoría"
                                        :error-messages="errors"
                                    />
                                </validation-provider>

                                <v-text-field
                                    v-model="fields.presentation"
                                    label="Presentación"
                                    counter
                                ></v-text-field>

                                <v-textarea
                                    v-model="fields.description"
                                    label="Descripción"
                                    counter
                                ></v-textarea>

                                <v-file-input
                                    class="mb-4"
                                    v-model="fields.thumbnail"
                                    label="Vista previa"
                                    prepend-icon=""
                                    append-icon="mdi-camera-outline"
                                    hint="Recomendado: 200 x 150 px"
                                    persistent-hint
                                    show-size
                                />

                                <v-switch
                                    v-model="fields.isWholesaler"
                                    label="Mayorista"
                                />

                                <v-row align="center">
                                    <v-col class="flex-grow-0">
                                        <v-icon>mdi-currency-usd</v-icon>
                                    </v-col>
                                    <v-col class="px-0">
                                        <span class="title">Precio</span>
                                    </v-col>
                                </v-row>

                                <v-text-field
                                    v-model="fields.price"
                                    label="Precio"
                                    prefix="$"
                                ></v-text-field>

                                <v-select
                                    label="Impuesto"
                                    v-model="fields.tax"
                                    :items="taxOptions"
                                    prefix="%"
                                />
                            </v-form>
                        </v-col>
                    </v-row>

                    <v-row align="center" class="section-title">
                        <v-col class="flex-grow-0">
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-col>
                        <v-col class="px-0">
                            <span class="title">Stock</span>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-switch
                                v-model="hasStock"
                                label="Stockeable"
                            />

                            <v-text-field
                                v-if="hasStock"
                                label="Corte de Venta"
                                v-model="stockFields.stopSellingStockLevel"
                                suffix="Unidades"
                            ></v-text-field>

                            <v-text-field
                                v-if="hasStock"
                                label="Stock Mínimo"
                                v-model="stockFields.minimumStockLevel"
                            ></v-text-field>

                            <v-switch
                                v-if="hasStock"
                                v-model="stockFields.perishable"
                                label="Perecedero">
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-form>
            </validation-observer>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="onCancelSelected">Cancelar</v-btn>
                <v-btn color="primary" @click="onSaveSelected">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import ProductCategorySelect from '@/components/Product/ProductCategorySelect';

    export default {
        components: {
            ProductCategorySelect,
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                active: false,

                valid: false,

                categories: [],

                hasStock: false,

                fields: {
                    name: '',
                    category: null,
                    price: '',
                    tax: '',
                    presentation: '',
                    description: '',
                    isWholesaler: false
                },

                stockFields: {
                    minimumStockLevel: 0,
                    stopSellingStockLevel: 0,
                    perishable: false
                },

                taxOptions: [
                    { text: '0', value: 0.0 },
                    { text: '21', value: 21.0 },
                    { text: '10.5', value: 10.5 }
                ]
            };
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            stock() {
                return this.$store.getters['stock/getByProductId'](this.product.id);
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                let payload = { ...this.fields };
                if(this.fields.category) {
                    payload.category = this.fields.category.id;
                }

                let stockPayload = { ...this.stockFields };

                try {
                    let product = await this.$store.dispatch('products/create', { data: payload });

                    if(this.hasStock) {
                        stockPayload.product = product.id;
                        await this.$store.dispatch('stock/create', { data: stockPayload });
                    }

                    this.$store.dispatch('app/setStatusMessage', 'Producto creado');

                    this.active = false;
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .header {
        h4.headline {
            font-size: 20px !important;
        }

        .main-image {
            background-color: white;
            margin-right: 20px;

            img {
                width: 200px;
            }
        }

        .v-btn {
            margin: 0;
        }
    }
</style>
