<template>
    <v-container v-if="product" class="pa-6">
        <v-toolbar flat dense color="transparent" height="30" class="mb-2">
            <v-toolbar-title>
                <span class="headline">{{ product.name | capitalize }}</span>
                <span class="ml-2 text--lighten-1 grey--text">#{{ product.id }}</span>
            </v-toolbar-title>

            <v-spacer/>

            <v-toolbar-title>
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon small v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="onEditSelected">
                            <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-title>
        </v-toolbar>

        <v-row>
            <v-col class="flex-grow-0 px-0">
                <v-img
                    class="main-image mr-2"
                    :src="image"
                    height="96"
                    width="128">
                </v-img>
            </v-col>

            <v-col class="px-0">
                <span class="subtitle-1 d-block">{{ product.category.name }}</span>

                <v-chip v-if="product.publicSale" label color="green" text-color="white" class="small">Publicado</v-chip>
                <v-chip v-else label color="red" text-color="white" class="small">No Publicado</v-chip>
            </v-col>
        </v-row>

        <v-tabs
            v-model="activeTab"
            class="elevation-0"
        >
            <v-tab>Detalles</v-tab>
            <v-tab>Movimientos</v-tab>

            <v-tab-item>
                <v-col cols="12" v-if="stock">
                    <v-row align="center">
                        <v-col class="flex-grow-0">
                            <v-icon>mdi-package-variant-closed</v-icon>
                        </v-col>
                        <v-col class="px-0">
                            <span class="title">Stock</span>
                        </v-col>
                    </v-row>

                    <v-list dense class="t-info-list">
                        <v-list-item>
                            <v-list-item-content cols="2">Stock actual</v-list-item-content>
                            <v-list-item-content cols="10">{{ stock.currentStock }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content cols="2">Stock mínimo</v-list-item-content>
                            <v-list-item-content cols="10">{{ stock.minimumStockLevel}}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content cols="2">Corte de venta</v-list-item-content>
                            <v-list-item-content cols="10">{{ stock.stopSellingStockLevel }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content cols="2">Perecedero:</v-list-item-content>
                            <v-list-item-content cols="10">{{ stock.perishable ? 'Si' : 'No' }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>

                <v-col cols="12">
                    <v-row align="center">
                        <v-col class="flex-grow-0">
                            <v-icon>mdi-currency-usd</v-icon>
                        </v-col>
                        <v-col class="px-0">
                            <span class="title">Precio</span>
                        </v-col>
                    </v-row>

                    <v-list dense class="t-info-list">
                        <v-list-item>
                            <v-list-item-content cols="2">Precio</v-list-item-content>
                            <v-list-item-content cols="10">{{ product.price | toCurrency }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content cols="2">Impuesto</v-list-item-content>
                            <v-list-item-content cols="10">{{ product.tax | toPercentage }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>

                <v-col cols="12">
                    <v-row align="center">
                        <v-col class="flex-grow-0">
                            <v-icon>mdi-information-outline</v-icon>
                        </v-col>
                        <v-col class="px-0">
                            <span class="title">Detalles</span>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <span>{{ product.description }}</span>
                        </v-col>

                        <v-col cols="12">
                            <span>Presentación</span>: <span>{{ product.presentation }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-tab-item>

            <v-tab-item>
                <v-row>
                    <stock-movements-table :product="product"></stock-movements-table>
                </v-row>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
    import StockMovementsTable from '@/components/Stock/StockMovementsTable';

    export default {
        components: {
            StockMovementsTable
        },

        props: {
            product: Object
        },

        data() {
            return {
                activeTab: 0
            };
        },

        computed: {
            canEdit() {
                return true;
            },

            image() {
                if(this.product.thumbnail) {
                    return process.env.VUE_APP_TALLO_CDN + '/' + this.product.thumbnail;
                }
                else {
                    return require('@/assets/blank_img.png');
                }
            },

            stock() {
                return this.$store.getters['stock/getByProductId'](this.product.id);
            }
        },

        watch: {},

        methods: {
            onEditSelected() {
                this.$emit('edit');
            },

            onStockIngressSelected() {

            },

            onStockEgressSelected() {

            }
        }
    };
</script>

<style lang="scss" scoped>
    .header {
        .main-image {
            background-color: white;
            margin-right: 20px;

            img {
                width: 200px;
            }
        }

        .v-btn {
            margin: 0;
        }
    }

    .info-list {
        font-size: 13px;
        padding-left: 30px !important;

        .flex {
            &.xs2, &.xs10 {
                padding-top: 0.2em;
            }
        }
    }

    .v-chip.small {
        height: 20px;
    }
</style>
