<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container v-if="active" class="pa-10">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col class="py-0">
                            <span class="headline blue-grey--text text--darken-3">Movimientos de Stock</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Comentario"
                        v-model="comment"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row class="pb-12">
                <v-col cols="12">
                    <v-data-table
                        hide-default-footer
                        hide-default-header
                        :items="items"
                        :headers="headers"
                        class="elevation-0"
                    >
                        <template v-slot:headers>
                            <td style="width: 100px;">Cantidad</td>
                            <td>Producto</td>
                            <td></td>
                        </template>

                        <template v-slot:item.quantity="{item}">
                            {{ item.quantity }}
                        </template>

                        <template v-slot:item.product="{item}">
                            {{ item.name }}
                        </template>

                        <template v-slot:item.actions="{item}">
                            <v-btn text icon @click="remove(props.item)"><v-icon small>mdi-close</v-icon></v-btn>
                        </template>

                        <template v-slot:body.append>
                            <tr>
                                <td style="width: 96px;">
                                    <v-text-field
                                        ref="quantityField"
                                        label="Cantidad"
                                        v-model="quantity"
                                        @focus="$event.target.select()"
                                    ></v-text-field>
                                </td>

                                <td>
                                    <product-select
                                        v-model="product"
                                        :products="products"
                                    />
                                </td>

                                <td>
                                    <v-btn text icon
                                        ref="addButton"
                                        color="primary"
                                        @click="add"
                                        :disabled="!product || !quantity"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" text @click="onCancelSelected">
                    Cancelar
                </v-btn>

                <v-btn color="green" dark @click="onConfirmSelected" :loading="isLoading">
                    Ingresar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import ProductSelect from '@/components/Product/ProductSelect';

    export default {
        components: {
            ProductSelect
        },

        props: {
            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                product: null,
                quantity: 0,
                comment: '',

                items: [],

                headers: [
                    { text: '', value: 'quantity' },
                    { text: '', value: 'product' },
                    { text: '', value: 'actions' }
                ]
            };
        },

        computed: {
            products() {
                return this.$store.getters['products/getAll']();
            },

            isLoading() {
                return this.$store.getters['stock/isLoading']();
            }
        },

        methods: {
            get(id) {
                for(let p of this.items) {
                    if(p.id === id) {
                        return p;
                    }
                }

                return null;
            },

            add() {
                let existingItem = this.get(this.product.id);
                if(existingItem) {
                    existingItem.quantity = parseInt(existingItem.quantity) + parseInt(this.quantity);
                }
                else {
                    this.items.push({
                        id: this.product.id,
                        name: this.product.name,
                        quantity: this.quantity,
                        price: this.product.price,
                        product: {
                            id: this.product.id,
                            thumbnail: this.product.thumbnail
                        }
                    });
                }

                // reset
                this.quantity = 1;
                this.product = null;

                requestAnimationFrame(() => {
                    return this.$refs.quantityField.focus();
                });
            },

            remove(item) {
                let idx = this.items.indexOf(item);
                this.items.splice(idx, 1);

                this.$emit('input', this.items);
            },

            onConfirmSelected(value) {
                let data = {
                    comment: this.comment,
                    movements: this.items
                };

                this.$store.dispatch('stock/addMovements', data).then(() => {
                    this.$emit('update:active', false);
                });
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
